import React, { useEffect, useState } from "react";

import axios from "axios";
import Markdown from "markdown-to-jsx";
import styled from "styled-components";

import FavoriteButton from "./FavoriteButton";
import type {
  FavoritesProps,
  PortalContextProps,
  SlideoutData,
} from "../types";
import {
  Button,
  CopyButton,
  IconTrueFalse,
  StatusPill,
  Table,
  TableLink,
  ToolTip,
} from "~/src/CommonComponents";
import type { ListInsightsDashboard, UserFavorites } from "~/src/types";
import { INSIGHTS_PORTAL_URLS } from "~/src/urls";
import { gridSpacing } from "~/src/utils";
import { readablePhoneNumber } from "~/src/utils/tsUtils";

export const showDashboardDetails = (
  obj: ListInsightsDashboard,
  setSlideoutData: (value: SlideoutData) => void,
  setDashboardSlideoutOpen: (value: boolean) => void,
  customerName = "Customer",
  // documentationUrl?: string,
) => {
  setSlideoutData([
    [
      {
        title: "",
        content: obj.embedded_details.lucerna_managed ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="secondary"
              text="User Guide"
              onClick={() => {
                window.open(
                  `https://app.gitbook.com/o/6HeSOxUGAlUHXveAaSRQ/s/Qs14sBKtV4f3djj3b3Ei/guides${INSIGHTS_PORTAL_URLS.baseUrl}/${obj.analytic_domain.path}/${obj.identifier}`,
                  "_blank",
                );
              }}
            />
          </div>
        ) : null,
      },
      {
        title: "Dashboard Name",
        content: obj.name,
      },
      {
        title: "Short Description",
        content: obj.short_description,
      },
    ],
    [
      {
        title: "Information",
        content: obj.embedded_details?.information ? (
          <Markdown>{obj.embedded_details?.information}</Markdown>
        ) : undefined,
      },
    ],
    [
      {
        title: "Description",
        content: obj.description ? (
          <Markdown>{obj.description}</Markdown>
        ) : undefined,
      },
    ],
    [
      {
        title: "Category",
        content: obj.analytic_domain.name,
      },
      {
        title: "Dashboard Quicksight ID",
        content: obj.embedded_details?.dashboard_uuid,
      },
      {
        title: "Dashboard Quicksight Name",
        content: obj.embedded_details?.original_name,
      },
    ],
    [
      {
        title: "Contains PHI",
        content: (
          <IconTrueFalse value={obj.embedded_details?.contains_phi || null} />
        ),
      },
      {
        title: "Managed By",
        content: (
          <StatusPill
            text={
              obj.embedded_details?.lucerna_managed ? "Lucerna" : customerName
            }
            type={obj.embedded_details?.lucerna_managed ? "primary" : "default"}
          />
        ),
      },
    ],
    [
      {
        title: "Support Contact Name",
        content: obj.embedded_details?.support_contact?.name,
      },
      {
        title: "Support Contact Title",
        content: obj.embedded_details?.support_contact?.title,
      },
      {
        title: "Support Contact Email",
        content: obj.embedded_details?.support_contact?.email,
      },
      {
        title: "Support Contact Phone",
        content:
          readablePhoneNumber(obj.embedded_details?.support_contact?.phone) ??
          "",
      },
    ],
    [
      {
        title: "Documentation Links",
        content: (
          <Table
            hidePagination
            data={obj.embedded_details?.documentation_links || []}
            columns={[
              { Header: "Name", accessor: "name" },
              {
                Header: "URL",
                accessor: "url",
                Cell: (props: { value: string }) => (
                  <TableLink newTab url={props.value}>
                    {props.value}
                  </TableLink>
                ),
              },
            ]}
          />
        ),
      },
    ],
    [
      {
        title: "Shared Folder Permissions",
        content: (
          <Table
            hidePagination
            data={
              Array.isArray(obj.embedded_details?.shared_folder_permissions)
                ? obj.embedded_details?.shared_folder_permissions.map(
                    (permission: string) => {
                      return {
                        value: permission.split("/").at(-1),
                      };
                    },
                  )
                : []
            }
            columns={[{ Header: "Permissions", accessor: "value" }]}
          />
        ),
      },
    ],
  ]);
  setDashboardSlideoutOpen(true);
};

export const useFavorites = (objectTypes: string[]) => {
  const [favorites, setFavorites] = useState<FavoritesProps>({
    loading: true,
    error: false,
    data: [],
  });

  const getFavorites = () => {
    const params = new URLSearchParams();
    objectTypes.forEach((objectType) => {
      params.append("object_type", objectType);
    });
    params.append("page_size", "0");
    params.append("ordering", "created");

    axios
      .get(`/favorites/favorites?${params.toString()}`)
      .then((res) => {
        setFavorites({
          loading: false,
          error: false,
          data: res.data.result.results.sort(
            (a: { created: string }, b: { created: string }) =>
              new Date(b.created).getTime() - new Date(a.created).getTime(),
          ),
        });
      })
      .catch((err) => {
        console.error(err);
        setFavorites({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  useEffect(() => {
    getFavorites();
  }, []);

  return { ...favorites, getFavorites };
};

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ${gridSpacing[5]}px ${gridSpacing[7]}px;
`;

export const renderDashboardActions = (
  dashboard: ListInsightsDashboard,
  favoritesData: UserFavorites[],
  getFavorites: () => void,
  setSlideoutData: (value: SlideoutData) => void,
  setDashboardSlideoutOpen: (value: boolean) => void,
  customerName = "Customer",
  // documentationUrl?: string,
) => (
  <>
    <ToolTip text="Favorite">
      <FavoriteButton
        favorite={favoritesData.some(
          (favorite) =>
            favorite.object_type &&
            favorite.object_type === "insights_portal" &&
            favorite.object_id === dashboard.uuid,
        )}
        objectType="insights_portal"
        objectId={dashboard.uuid}
        refreshFavorites={getFavorites}
      />
    </ToolTip>

    <CopyButton
      value={new URL(
        `${INSIGHTS_PORTAL_URLS.baseUrl}/${dashboard.identifier}`,
        window.location.origin,
      ).toString()}
      style={{
        margin: `0 ${gridSpacing[3]}px`,
      }}
      iconStyle={{ fontSize: 18 }}
    />

    <Button
      style={{ paddingLeft: 0 }}
      type="link"
      icon="fa-info-circle"
      onClick={() => {
        showDashboardDetails(
          dashboard,
          setSlideoutData,
          setDashboardSlideoutOpen,
          customerName,
          // documentationUrl,
        );
      }}
      tooltip="Info"
    />
  </>
);

export const PortalContext = React.createContext<PortalContextProps>({
  filters: {},
  setFilters: () => {},
  filterOptions: {},
  setFilterOptions: () => {},
});
