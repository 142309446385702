import React, { Suspense, useEffect } from "react";

import { Loading, ToastContainer } from "@commonComponents";
import * as Sentry from "@sentry/react";
import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
import axios from "axios";
import Highcharts from "highcharts";
import queryString from "query-string";
import { createRoot } from "react-dom/client";
import "sweetalert2/src/sweetalert2.scss";
import "./index.css";
import "react-circular-progressbar/dist/styles.css";
import "reactflow/dist/style.css";
import { Provider } from "react-redux";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
ModuleRegistry.registerModules([AllCommunityModule]);

const App = React.lazy(() => import("./App"));
import store from "./store";

if (window.location.hostname !== "localhost") {
  const environment = window.location.hostname.split(".").shift();

  Sentry.init({
    environment,
    dsn: "https://d638b6e88bf044eebc803b4a9851d1fa@o524965.ingest.sentry.io/5454563",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.captureConsoleIntegration({
        levels: ["error"],
      }),
      Sentry.launchDarklyIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        maskAllInputs: true,
      }),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    normalizeDepth: 15,
  });
}

// Import font awesome kit - we do have local version of FA but this will always ensure we have the latest
(function () {
  const wa = document.createElement("script");
  wa.type = "text/javascript";
  wa.src = "https://kit.fontawesome.com/0870df4268.js";
  wa.crossOrigin = "anonymous";
  const s = document.getElementsByTagName("script")[0];
  s.parentNode?.insertBefore(wa, s);
})();

// We need to check if we are working locally or on a server, if host contains localhost we are working locally
if (window.location.hostname === "localhost") {
  axios.defaults.baseURL = `${window.location.origin}/api/v1/`;
} else {
  axios.defaults.baseURL = `https://internal-api.${window.location.host}/api/v1/`;
}
axios.defaults.paramsSerializer = (params) =>
  queryString.stringify(params, { arrayFormat: "none" });

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
  chart: {
    style: {
      fontFamily: '"Manrope", sans-serif',
    },
  },
});

function AppRoot() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <Suspense fallback={<Loading center />}>
        <App />
      </Suspense>
    </Provider>
  );
}

const container = document.getElementById("app-root");
if (!container) throw new Error("Failed to find the app-root element");
const root = createRoot(container);

root.render(<AppRoot />);
